// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ResourceFormPage from './ResourceFormPage'
import EditInvitationForm from './EditInvitationForm'

/* @ts-expect-error auto-src: noflow */
const EditInvitation = (props) => (
  <ResourceFormPage {...props}>{EditInvitationForm}</ResourceFormPage>
)

export default EditInvitation
