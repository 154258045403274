import { Component } from 'react'
import type { LoginPaths } from 'login/types'
import NotificationsCenter from './NotificationsCenter'
import UnlockForm from './UnlockForm'

type Props = {
  paths: LoginPaths
  serverFeedback: string | null | undefined
}

export default class UnlockAccount extends Component<Props> {
  handleSubmit = (): void => {
    /* @ts-expect-error auto-src: noflow */
    this.notificationsRef.openProgressDialog()
  }

  render(): JSX.Element {
    const { serverFeedback, ...rest } = this.props

    return (
      <>
        <NotificationsCenter
          ref={(notifications) => {
            /* @ts-expect-error auto-src: noflow */
            this.notificationsRef = notifications
          }}
          dialogText="Please wait..."
          serverFeedback={serverFeedback}
        />
        <UnlockForm
          {...rest}
          ref={(form) => {
            /* @ts-expect-error auto-src: noflow */
            this.formRef = form
          }}
          onSubmit={this.handleSubmit}
          paths={this.props.paths}
        />
      </>
    )
  }
}
