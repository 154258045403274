// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { TextField } from '@material-ui/core'
import type { TextFieldProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    width: 290,
    marginTop: 18,
  },
})
const useInputStyles = makeStyles({
  root: {
    fontFamily: 'Open Sans',
  },
  formControl: {
    color: 'white',
  },
  underline: {
    borderBottomColor: 'white',
    '&:hover': {
      borderBottomColor: 'white',
      '&&:before': {
        borderBottomColor: 'white',
      },
      '&&:after': {
        borderBottomColor: 'white',
      },
    },
    '&:before': {
      borderBottomColor: 'rgba(255,255,255,0.3)',
    },
    '&:after': {
      borderBottomColor: 'rgba(255,255,255,0.3)',
    },
  },
})
const useInputLabelStyles = makeStyles({
  root: {
    color: 'rgba(255,255,255,0.5)',
  },
  animated: {
    color: 'rgba(255,255,255,0.5) !important',
  },
})
const useformHelperTextStyles = makeStyles({
  root: {
    color: 'rgb(255, 171, 145) !important',
  },
})

/* @ts-expect-error auto-src: noflow */
const useColoredFormHelperTextStyles = ({ helperColor }) =>
  makeStyles(() => ({
    root: {
      color: `${helperColor} !important`,
    },
  }))

export type Props = {
  FormHelperTextProps: any
  textFieldRef?: TextFieldProps['ref']
  helperColor?: string
} & TextFieldProps

const StyledTextField = ({
  FormHelperTextProps,
  textFieldRef,
  helperColor,
  ...props
}: Props) => {
  const classes = useStyles()
  const inputClasses = useInputStyles()
  const inputLabelClasses = useInputLabelStyles()
  const defaultFormHelperTextClasses = useformHelperTextStyles()
  const coloredFormHelperTextClasses = useColoredFormHelperTextStyles({
    helperColor,
  })()
  const formHelperTextClasses = helperColor
    ? coloredFormHelperTextClasses
    : defaultFormHelperTextClasses

  return (
    <TextField
      ref={textFieldRef}
      classes={classes}
      {...props}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: formHelperTextClasses,
      }}
      InputLabelProps={{
        classes: inputLabelClasses,
        ...props.InputLabelProps,
      }}
      InputProps={{
        classes: inputClasses,
        ...props.InputProps,
      }}
    />
  )
}

export default StyledTextField
