// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    color: 'white',
    backgroundColor: '#4a6c9e',
    marginBottom: 32,
    marginTop: 36,
    fontFamily: 'Open Sans',
    '&:hover': {
      backgroundColor: 'rgb(74,108,158, 0.7)',
    },
  },
})

type Props = {
  label: string
}

const SubmitButton = ({ label }: Props) => {
  const classes = useStyles()

  return (
    <Button classes={classes} fullWidth type="submit">
      {label}
    </Button>
  )
}

export default SubmitButton
