// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import { Component } from 'react'
import AuthenticityToken from './AuthenticityToken'
import InstructionsText from './InstructionsText'
import NavigationLink from './NavigationLink'
import SubmitButton from './SubmitButton'

export default class UnlockForm extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    email: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    paths: PropTypes.object.isRequired,
  }

  UNSAFE_componentWillMount() {
    /* @ts-expect-error auto-src: noflow */
    if (!this.props.email) {
      /* @ts-expect-error auto-src: noflow */
      window.location.pathname = this.props.paths.newSession
    }
  }

  handleFormSubmit = () => {
    /* @ts-expect-error auto-src: noflow */
    this.props.onSubmit()
  }

  render() {
    return (
      <form
        /* @ts-expect-error auto-src: noflow */
        action={this.props.paths.createUnlock}
        method="post"
        onSubmit={this.handleFormSubmit}
      >
        <InstructionsText>
          Your account has been locked. Please check your email for unlock
          instructions.
        </InstructionsText>
        <AuthenticityToken />
        <input
          ref={(email) => {
            /* @ts-expect-error auto-src: noflow */
            this.emailRef = email
          }}
          name="user[email]"
          type="hidden"
          /* @ts-expect-error auto-src: noflow */
          value={this.props.email || ''}
        />
        <SubmitButton label="RESEND INSTRUCTIONS" />
        {/* @ts-expect-error auto-src: noflow */}
        <NavigationLink href={this.props.paths.newSession}>
          Sign In
        </NavigationLink>
      </form>
    )
  }
}
