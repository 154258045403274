import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'

const buttonStyles = makeStyles({
  root: {
    padding: '16px 24px 16px 16px',
    width: '100%',
    textTransform: 'none',
    borderRadius: '4px',
    background: 'white',
    marginBottom: '12px',
  },
  focusVisible: {
    outline: 'revert',
  },
})

const styles = makeStyles({
  image: { width: '24px', height: '24px', paddingRight: '12px' },
  text: { fontSize: '14px', fontWeight: 600 },
})

interface MultiSSOButtonProps {
  'data-testid': string
  iconPath: string
  text: string
  redirectUrl: string
}

const MultiSSOButton = ({
  'data-testid': dataTestid,
  iconPath,
  text,
  redirectUrl,
}: MultiSSOButtonProps): JSX.Element => {
  const buttonClasses = buttonStyles()
  const classes = styles()

  const onClick = () => {
    window.location.href = redirectUrl
  }

  return (
    <Button
      classes={buttonClasses}
      data-testid={dataTestid}
      disableRipple
      onClick={onClick}
      type="button"
      variant="contained"
    >
      <img alt={text} className={classes.image} src={iconPath} />
      <Typography className={classes.text}>{text}</Typography>
    </Button>
  )
}

export default MultiSSOButton
