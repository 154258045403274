// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import { instructions } from '../styles/specificStyles'

/* @ts-expect-error auto-src: noflow */
const InstructionsText = ({ children, ...rest }) => (
  /* @ts-expect-error auto-src: noflow */
  <p style={instructions} {...rest}>
    {children}
  </p>
)

InstructionsText.propTypes = {
  children: PropTypes.any,
}
export default InstructionsText
