// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { stackedMenuButton } from '../styles/specificStyles'
import { buttonStyles } from '../styles/styleProps'
import InstructionsText from './InstructionsText'
import ThemeProvider from './ThemeProvider'

type Props = {
  authorizedPaths: any
}
const useButtonStyles = makeStyles({
  root: { ...stackedMenuButton, ...buttonStyles },
})
const useStyles = makeStyles({
  instructions: {
    paddingBottom: '10px !important',
  },
})

const UnauthorizedNavigation = ({ authorizedPaths }: Props) => {
  const buttonClasses = useButtonStyles()
  const classes = useStyles()

  return (
    <ThemeProvider>
      <InstructionsText className={classes.instructions}>
        Access Denied
      </InstructionsText>
      {Object.keys(authorizedPaths).map((label, index) => (
        <Button
          key={index}
          classes={buttonClasses}
          fullWidth
          href={authorizedPaths[label]}
          variant="contained"
        >
          {label}
        </Button>
      ))}
    </ThemeProvider>
  )
}

export default UnauthorizedNavigation
