import {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import type { TextFieldProps } from '@material-ui/core'
import StyledTextField from '../StyledTextField'
import validatePresence from '../../lib/validatePresence'
import {
  passwordScoreColors,
  passwordStrengthsList,
  userDefinedWords,
} from '../lib'

export type Props = {
  email: string | undefined
  firstName: string | undefined
  initialError: string | undefined | null
  label: string | undefined
  lastName: string | undefined
  minScore: number
  name: string
  onChange: TextFieldProps['onChange']
  textFieldLabel?: string | undefined
}

const PasswordTextField = forwardRef<{ validate: () => boolean }, Props>(
  (
    {
      email,
      firstName,
      initialError,
      label,
      lastName,
      minScore,
      name,
      onChange,
      textFieldLabel,
    }: Props,
    ref,
  ): JSX.Element => {
    const [error, setError] = useState<string | undefined | null>(initialError)
    const [score, setScore] = useState(0)
    const [isValidable, setValidable] = useState(false)
    const [fieldValue, setFieldValue] = useState('')
    const [passwordStats, setPasswordStats] = useState<{
      feedback: {
        warning: string | null
        suggestions: string[] | null
      }
    } | null>(null)

    const textFieldRef: TextFieldProps['ref'] = createRef()

    const localUserDefinedWords = useMemo<string[]>(
      (): string[] =>
        [...userDefinedWords, firstName, lastName, email].filter(
          (word): word is string => Boolean(word),
        ),
      [email, firstName, lastName],
    )

    const getPasswordStats = useCallback(
      async (value: string) => {
        const zxcvbn = await import(
          /* webpackChunkName: "vendors-zxcvbn" */ 'zxcvbn'
        )

        return zxcvbn.default(value, localUserDefinedWords)
      },
      [localUserDefinedWords],
    )

    const validate = useCallback(() => {
      if (fieldValue) {
        return score >= minScore
      }

      setError(validatePresence(textFieldLabel || 'password', fieldValue))

      return false
    }, [fieldValue, minScore, score, textFieldLabel])

    useImperativeHandle(
      ref,
      () => ({
        validate,
      }),
      [validate],
    )

    const handleFocus = () => {
      setValidable(true)
    }

    const handleChange: TextFieldProps['onChange'] = (event) => {
      const eventTargetValue = event.target.value

      if (onChange) {
        onChange(event)
      }

      getPasswordStats(eventTargetValue).then((newStats) => {
        setScore(newStats.score)
        setError(eventTargetValue && passwordStrengthsList[newStats.score])
        setFieldValue(eventTargetValue)
        setPasswordStats({ feedback: newStats.feedback })
      })
    }

    useEffect(() => {
      import(/* webpackChunkName: "vendors-zxcvbn" */ 'zxcvbn')
    }, [])

    return (
      <>
        <StyledTextField
          FormHelperTextProps={{
            error: Boolean(error),
            'data-testid': 'password-text-field-helper-text',
          }}
          helperColor={passwordScoreColors[passwordStrengthsList[score]]}
          helperText={error}
          InputLabelProps={{
            /* @ts-expect-error: MUI does not accept data-testid */
            'data-testid': 'password-text-field-label',
            htmlFor: name,
          }}
          inputProps={{
            'data-testid': 'password-text-field',
            id: name,
          }}
          label={label || 'Password'}
          name={name}
          onChange={(isValidable && handleChange) || undefined}
          onFocus={handleFocus}
          textFieldRef={textFieldRef}
          type="password"
        />
        <div
          data-testid="password-text-field-feedback"
          style={{ color: passwordScoreColors[passwordStrengthsList[score]] }}
        >
          {(fieldValue &&
            (
              (passwordStats &&
                passwordStats.feedback.warning && [
                  passwordStats.feedback.warning,
                ]) ||
              []
            )
              .concat(passwordStats?.feedback.suggestions || [])
              .map((item, index) => (
                <p key={index}>{item.replace(/\.$/, '')}.</p>
              ))) ||
            null}
        </div>
      </>
    )
  },
)

export default PasswordTextField
