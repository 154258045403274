// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Snackbar, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import OverlayProgressDialog from './OverlayProgressDialog'

export default class NotificationsCenter extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    dialogText: PropTypes.string,
    serverFeedback: PropTypes.string,
    snackbarProps: PropTypes.object,
  }

  /* @ts-expect-error auto-src: noflow */
  constructor(props, context) {
    super(props, context)
    this.state = {
      requesting: false,
      /* @ts-expect-error auto-src: noflow */
      snackbarText: this.props.serverFeedback || '',
    }
  }

  /* @ts-expect-error auto-src: noflow */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      snackbarText: nextProps.serverFeedback,
    })
  }

  handleClearSnackbarText = () => {
    this.setState({
      snackbarText: '',
    })
  }

  openProgressDialog = () => {
    this.setState({
      requesting: true,
    })
  }

  closeProgressDialog = () => {
    this.setState({
      requesting: false,
    })
  }

  render() {
    return (
      <>
        <OverlayProgressDialog
          /* @ts-expect-error auto-src: noflow */
          dialogText={this.props.dialogText}
          /* @ts-expect-error auto-src: noflow */
          open={this.state.requesting}
        />
        <Snackbar
          action={
            <IconButton color="inherit" onClick={this.handleClearSnackbarText}>
              <CloseIcon />
            </IconButton>
          }
          autoHideDuration={16000}
          /* @ts-expect-error auto-src: noflow */
          message={<span>{this.state.snackbarText}</span>}
          onClose={this.handleClearSnackbarText}
          /* @ts-expect-error auto-src: noflow */
          open={!!this.state.snackbarText}
          /* @ts-expect-error auto-src: noflow */
          position="bottom-center"
        />
      </>
    )
  }
}
