// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* @ts-expect-error auto-src: noflow */
export default function dismissibleSnackbarProps(onActionClick) {
  return {
    action: 'dismiss',
    autoHideDuration: 0,
    onActionClick,
  }
}
