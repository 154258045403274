// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
type Props = {
  method?: string
}
const DEFAULT_METHOD = 'PUT'

const HttpMethodInput = ({ method }: Props) => (
  <input name="_method" type="hidden" value={method || DEFAULT_METHOD} />
)

export default HttpMethodInput
