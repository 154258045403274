import WebpackerReact from 'webpacker-react'
import EditInvitation from '../components/EditInvitation'
import EditPassword from '../components/EditPassword'
import EditRegistration from '../components/EditRegistration'
import LockedAccount from '../components/LockedAccount'
import Login from '../components/Login'
import ResetPassword from '../components/ResetPassword'
import UnauthorizedNavigation from '../components/UnauthorizedNavigation'
import SsoLogout from '../components/SsoLogout'

WebpackerReact.setup({
  EditInvitation,
  EditPassword,
  EditRegistration,
  LockedAccount,
  Login,
  ResetPassword,
  SsoLogout,
  UnauthorizedNavigation,
})
