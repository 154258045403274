import { mediumWhite } from './additionalColors'

const grey850 = '#323232'

export default {
  checkbox: {
    display: 'inline-block',
    fontWeight: '300',
    width: 'auto',
  },
  checkboxIcon: {
    height: '15px',
    marginTop: '2.1px',
    marginRight: '8px',
    width: '15px',
  },
  checkboxLabel: {
    color: mediumWhite,
    fontWeight: '300',
    width: 'auto',
  },
  floatingLabel: {
    fontWeight: 'normal',
  },
  link: {
    color: mediumWhite,
    textDecoration: 'none',
  },
  snackbarBody: {
    backgroundColor: grey850,
    paddingLeft: '35px',
  },
  textField: {
    height: '76px',
    width: '100%',
    color: 'white',
  },
}
