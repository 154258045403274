import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import kebabCase from 'lodash/kebabCase'
import MultiSSOButton from './MultiSSOButton'

const styles = makeStyles({
  text: { marginBottom: '16px', fontSize: '14px', color: 'white' },
})

export const MultiSSOButtons = (): JSX.Element => {
  const classes = styles()

  const buttons = [
    {
      id: 'Office365',
      iconUrl:
        'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/office-365-icon.png',
      text: 'Log in with Office365',
      href: '',
    },
    {
      id: 'Google',
      iconUrl:
        'https://cdn.iconscout.com/icon/free/png-512/free-google-160-189824.png?f=webp&w=256',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      href: '',
    },
  ]

  return (
    <Box alignItems="center" display="flex" flexDirection="column" mt={5.25}>
      {buttons.length > 0 && (
        <Typography className={classes.text}>
          Or choose another login method
        </Typography>
      )}
      {buttons.map((button) => (
        <MultiSSOButton
          key={button.id}
          data-testid={`multi-sso-button-${kebabCase(button.id)}`}
          iconPath={button.iconUrl}
          redirectUrl={button.href}
          text={button.text}
        />
      ))}
    </Box>
  )
}

export default MultiSSOButtons
