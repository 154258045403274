// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import { Component } from 'react'
import InstructionsText from './InstructionsText'
import SubmitButton from './SubmitButton'

export default class SsoLogout extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    paths: PropTypes.object.isRequired,
  }

  /* @ts-expect-error auto-src: noflow */
  handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    /* @ts-expect-error auto-src: noflow */
    window.location.pathname = this.props.paths.newSession
  }

  render() {
    return (
      <>
        <InstructionsText>Signed out successfully.</InstructionsText>
        <form onSubmit={this.handleClick}>
          <SubmitButton label="SIGN IN" />
        </form>
      </>
    )
  }
}
