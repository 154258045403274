import { useState, useRef } from 'react'
import type { FormEvent } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import type { CheckboxProps } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import capitalize from 'lodash/capitalize'
import type { LoginPaths } from 'login/types'
import { textFieldStyles } from '../styles/styleProps'
import SubmitButton from './SubmitButton'
import validatePresence from '../lib/validatePresence'
import NavigationLink from './NavigationLink'
import AuthenticityToken from './AuthenticityToken'
import StyledTextField from './StyledTextField'
import type { Props as TextFieldProps } from './StyledTextField'
import MultiSSOButtons from './MultiSSO/MultiSSOButtons'

const StyledFormControlLabel = withStyles({
  label: {
    color: 'rgba(255, 255, 255, 0.75);',
    fontSize: '14px',
    lineHeight: '24px',
  },
  root: {
    color: 'white',
  },
})(FormControlLabel)
const StyledCheckbox = withStyles({
  root: {
    padding: 0,
    marginRight: 8,
    marginLeft: 8,
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})(
  (props: CheckboxProps): JSX.Element => (
    <Checkbox color="default" {...props} />
  ),
)

const getDefaultUserName = () =>
  window.document.cookie
    .split(';')
    .map((pair) => pair.split('='))
    .reduce((accum, [key, value]) => ({ ...accum, [key.trim()]: value }), {
      username: undefined,
    }).username || ''

const separationStyles = {
  height: 8,
  width: '200%',
}
const submitWaitTimeout = 1000

export type Props = {
  onSubmit: () => void
  ossProvider: string
  paths: LoginPaths
  resourceName: string
  userField: string
  multiSamlEnabled?: boolean
}

export const LoginForm = ({
  onSubmit,
  ossProvider,
  paths,
  resourceName,
  userField,
  multiSamlEnabled,
}: Props): JSX.Element => {
  const [errors, setErrors] = useState<
    Partial<Record<'email' | 'password', string | null>>
  >({})
  const [emailFieldValue, setEmailFieldValue] = useState(getDefaultUserName())
  const [passwordFieldValue, setPasswordFieldValue] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)
  const formRef = useRef<HTMLFormElement | null>(null)

  const addError = (field: 'email' | 'password', message: string | null) => {
    if (errors[field] !== message) {
      setErrors({ ...errors, [field]: message })
    }
  }

  const formHasErrors = () => {
    const emailError = validatePresence(userField, emailFieldValue)
    const passwordError = validatePresence('password', passwordFieldValue)

    setErrors({
      email: emailError,
      password: passwordError,
    })

    return emailError || passwordError
  }

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (formHasErrors()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      window.document.cookie = `username=${emailFieldValue}; path='/'`

      // Add a small timeout to allow the spinner animation to start
      // on Safari before navigating away
      if (!isSubmitted) {
        onSubmit()
        setSubmitted(true)
        setTimeout(() => {
          formRef.current?.submit()
        }, submitWaitTimeout)
        event.preventDefault()
      }
    }
  }

  const handleEmailChange: TextFieldProps['onChange'] = (event) => {
    const { value } = event.target

    if (value) {
      setEmailFieldValue(value)
      const error = validatePresence('email', value)

      addError('email', error)
    }
  }

  const handlePasswordChange: TextFieldProps['onChange'] = (event) => {
    const { value } = event.target

    if (value) {
      setPasswordFieldValue(value)
      const error = validatePresence('password', value)

      addError('password', error)
    }
  }

  const userFieldName = () => {
    if (ossProvider === 'ldap') {
      return userField
    }

    return `${resourceName}[${userField}]`
  }

  const passwordFieldName = () => {
    if (ossProvider === 'ldap') {
      return 'password'
    }

    return `${resourceName}[password]`
  }

  const showForgotPassword = () => {
    if (ossProvider === 'ldap') {
      return null
    }

    return (
      <NavigationLink href={paths.newPassword}>Forgot password?</NavigationLink>
    )
  }

  const form = (
    <form
      ref={formRef}
      action={paths.createSession}
      autoComplete="off"
      method="post"
      onSubmit={handleFormSubmit}
      role="form"
    >
      <AuthenticityToken />
      <input autoFocus name="oss_provider" type="hidden" value={ossProvider} />
      <StyledTextField
        className={textFieldStyles.className}
        defaultValue={getDefaultUserName()}
        FormHelperTextProps={{
          error: Boolean(errors.email),
        }}
        helperText={errors.email}
        inputProps={{ 'data-testid': 'email' }}
        label={capitalize(userField)}
        name={userFieldName()}
        onBlur={handleEmailChange}
        onChange={handleEmailChange}
      />
      <div style={separationStyles} />
      <StyledTextField
        className={textFieldStyles.className}
        FormHelperTextProps={{
          error: Boolean(errors.password),
        }}
        helperText={errors.password}
        inputProps={{ 'data-testid': 'password' }}
        label="Password"
        name={passwordFieldName()}
        onBlur={handlePasswordChange}
        onChange={handlePasswordChange}
        type="password"
      />
      <SubmitButton label="SIGN IN" />
      <StyledFormControlLabel
        control={<StyledCheckbox name="user[remember_me]" value={1} />}
        label="Remember me"
      />
      {showForgotPassword()}
    </form>
  )

  return (
    <>
      {form}
      {multiSamlEnabled && <MultiSSOButtons />}
    </>
  )
}

export default LoginForm
