// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function validateEquals(
  /* @ts-expect-error auto-src: noflow */
  pluralFieldName,
  /* @ts-expect-error auto-src: noflow */
  originalValue,
  /* @ts-expect-error auto-src: noflow */
  comparisonValue,
  message = `The ${pluralFieldName} don't match.`,
) {
  const valid = originalValue === comparisonValue

  return valid ? null : message
}
