// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import merge from 'lodash/merge'

export function getCSRFToken() {
  const header = document.querySelector('meta[name="csrf-token"]')

  /* @ts-expect-error auto-src: noflow */
  return header && header.content
}
export function getCSRFHeader() {
  return {
    'X-CSRF-Token': getCSRFToken(),
  }
}
/* @ts-expect-error auto-src: noflow */
export function getCSRFFetchOptions(fetchOptions) {
  return merge({}, fetchOptions, {
    credentials: 'same-origin',
    headers: getCSRFHeader(),
  })
}
